/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_GeneralSetting(state, item) {
    state.GeneralSetting.unshift(item);
  },

  SET_GeneralSetting(state, GeneralSetting) {
    state.GeneralSettings = GeneralSetting;
  },

  SET_ALLGeneralSetting(state, allGeneralSetting) {
    state.AllGeneralSettingS = allGeneralSetting;
  },

  UPDATE_GeneralSetting(state, item) {
    const Index = state.GeneralSettings.findIndex(p => p.Id == item.Id);
    Object.assign(state.GeneralSettings[Index], item);
  }
  
};
