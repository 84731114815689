/*=========================================================================================
  File Name: moduleGeneralSetting.js
  Description: GeneralSetting Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleGeneralSettingState.js";
import mutations from "./moduleGeneralSettingMutations.js";
import actions from "./moduleGeneralSettingActions.js";
import getters from "./moduleGeneralSettingGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
