/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addGeneralSetting({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("GeneralSettings/Add", item)
        .then(response => {
          commit("ADD_GeneralSetting", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAllCountries({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag",
          {
            headers: null
          }
        )
        .then(response => {
          commit("SET_ALLGeneralSetting", response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // https: //restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag
  fetchAllGeneralSettingList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/GeneralSettings/GetListOfGeneralSettings")
        .then(response => {
          if (response.status == 200) {
            commit("SET_GeneralSetting", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchGeneralSettingByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/GeneralSettings/GetGeneralSetting", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_GeneralSetting", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/GeneralSettings/Update?Id=" + item.Id, item)
        .then(response => {
          debugger
          commit("UPDATE_GeneralSetting", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCGeneralSettingById(context, GeneralSetting) {
    if (GeneralSetting.Id == undefined) {
      GeneralSetting.Id = "";
    }
   
    return new Promise((resolve, reject) => {
      axios
        .post("/GeneralSettings/Get", country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  

};
